import React, { useEffect, useState } from 'react';
import { Word, Phrase } from './styles';

interface TAnimatedTextProps {
  phrases: string[];
  active: boolean;
  delayBetweenWords?: number;
}

const getAnimationDelayByIndex = (
  index: number,
  delayBetweenWords: number,
): number => {
  return delayBetweenWords * index;
};

export const AnimatedByWordsText: React.FC<TAnimatedTextProps> = ({
  phrases,
  active = false,
  delayBetweenWords = 50,
}) => {
  const [isCurrentPhraseVisible, setIsCurrentPhraseVisible] = useState(true);
  const [isCurrentPhraseAnimating, setIsCurrentPhraseAnimating] =
    useState(false);

  const [isNextPhraseVisible, setIsNextPhraseVisible] = useState(false);
  const [isNextPhraseAnimating, setIsNextPhraseAnimating] = useState(false);
  const [isNextPhraseAppeared, setIsNextPhraseAppeared] = useState(false);

  useEffect(() => {
    if (active) {
      setIsCurrentPhraseAnimating(true);
    }
  }, [active]);

  const phrasesToAnimate = phrases.map((phrase, phraseIndex) => {
    const phraseWords = phrase.split(' ');

    const isCurrentPhrase = phraseIndex === 0;
    const isNextPhrase = phraseIndex === 1;

    return phraseWords.map((word, wordIndex) => {
      const isLastWord = wordIndex === phraseWords.length - 1;

      const handleWordAnimationStart = (): undefined => {
        if (isNextPhrase) {
          if (isLastWord) {
            setIsNextPhraseAppeared(true);
          }
        }
      };

      const handleWordAnimationEnd = (): undefined => {
        if (isCurrentPhrase) {
          if (isLastWord) {
            setIsCurrentPhraseAnimating(false);
            setIsCurrentPhraseVisible(false);

            setIsNextPhraseVisible(true);
            setIsNextPhraseAnimating(true);
          }
        }

        if (isNextPhrase) {
          if (isLastWord) {
            setIsNextPhraseAnimating(false);
          }
        }
      };

      return (
        <Word
          $animationDelay={getAnimationDelayByIndex(
            wordIndex,
            delayBetweenWords,
          )}
          $fadeIn={Boolean(isNextPhrase && isNextPhraseAnimating)}
          $fadeOut={Boolean(isCurrentPhrase && isCurrentPhraseAnimating)}
          $hidden={Boolean(isNextPhrase && !isNextPhraseAppeared)}
          key={word}
          onAnimationEnd={handleWordAnimationEnd}
          onAnimationStart={handleWordAnimationStart}
        >
          {word}&nbsp;
        </Word>
      );
    });
  });

  return phrasesToAnimate.map((phraseToAnimate, phraseToAnimateIndex) => {
    const isCurrentPhrase = phraseToAnimateIndex === 0;
    const isNextPhrase = phraseToAnimateIndex === 1;

    const isTextHidden =
      (isCurrentPhrase && !isCurrentPhraseVisible) ||
      (isNextPhrase && !isNextPhraseVisible);

    return (
      <Phrase $hidden={isTextHidden} key={phraseToAnimateIndex}>
        {phraseToAnimate}
      </Phrase>
    );
  });
};
