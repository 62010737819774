import { Root } from './styles';
import { type TSectionProps } from './types';

export const Section: React.FC<TSectionProps> = ({
  children,
  className,
  paddings,
}) => (
  <Root $paddings={paddings} className={className}>
    {children}
  </Root>
);
