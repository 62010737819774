'use client';

import styled from 'styled-components';
import { SectionTitle } from '@debt-relief/balanced-life-ui';

export const Root = styled.div`
  background: ${({ theme }) => theme.palette.Neutral50};
`;

export const Title = styled(SectionTitle)`
  text-align: center;
  margin-bottom: 6.4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 4rem;
  }
`;

export const TitleHighlight = styled.span`
  color: ${({ theme }) => theme.palette.TypoPrimaryMain};
`;

export const Items = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 -1.2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
    margin: 0 -1.6rem;
    padding: 0 0.8rem;
  }
`;

export const Item = styled.div`
  flex: 0 0 ${100 / 3}%;
  min-width: 0;
  padding: 0 1.2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 29.6rem;
    flex: 0 0 auto;
    padding: 0 0.8rem;
  }
`;
