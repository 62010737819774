import { generateRandomString } from '@debt-relief/utils';
import { formVersionHooks } from '@shared/api/form-version';
import { logger } from '@shared/logger';

const DEFAULT_VERSION = `generated_${generateRandomString(6)}`;
export const useFormVersion = (): string => {
  const { data, error, isError } = formVersionHooks.useGetVersion();

  if (isError) {
    logger.error(`Form version request failed: ${(error as Error).message}`);
    return DEFAULT_VERSION;
  }

  if (typeof data === 'string') {
    return data;
  }

  return DEFAULT_VERSION;
};
