'use client';

import type { MouseEvent } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ArrowRightIcon, CloseIcon } from '@debt-relief/common-ui';
import {
  ModalActionButton,
  ModalActionsBlock,
  ModalBody,
  ModalCloseAction,
  ModalHeader,
  ModalHeaderCloseActionBlock,
  ModalHeaderTitleBlock,
  ModalMain,
  ModalSubtitle,
  ModalTitle,
  Root,
} from './styles';
import type { TModalProps } from './types';

export const Modal: React.FC<TModalProps> = ({
  children,
  isShown,
  title = '',
  subtitle = '',
  onClose,
  template,
  onApply,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  const overlayRef = useRef<HTMLDivElement | null>(null);

  const onModalClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const onOverlayClick = (e: MouseEvent): undefined => {
    if (e.target === overlayRef.current) {
      onModalClose();
    }
  };

  useEffect(() => {
    const onEscPress = (e: KeyboardEvent): undefined => {
      if (e.key === 'Escape') {
        onModalClose();
      }
    };

    document.addEventListener('keydown', onEscPress);

    return () => {
      document.removeEventListener('keydown', onEscPress);
    };
  }, [onModalClose]);

  const defaultTemplate = (
    <ModalMain>
      {(Boolean(title) || Boolean(subtitle)) && (
        <ModalHeader>
          <ModalHeaderTitleBlock>
            {Boolean(title) && <ModalTitle>{title}</ModalTitle>}

            {Boolean(subtitle) && <ModalSubtitle>{subtitle}</ModalSubtitle>}
          </ModalHeaderTitleBlock>

          <ModalHeaderCloseActionBlock>
            <ModalCloseAction onClick={onModalClose} variant="text">
              <CloseIcon />
            </ModalCloseAction>
          </ModalHeaderCloseActionBlock>
        </ModalHeader>
      )}

      {Boolean(children) && <ModalBody>{children}</ModalBody>}

      <ModalActionsBlock>
        <ModalActionButton onClick={onApply} variant="fill">
          <span>Apply now</span>
          <ArrowRightIcon />
        </ModalActionButton>
      </ModalActionsBlock>
    </ModalMain>
  );

  return mounted
    ? createPortal(
        <Root $isShown={isShown} onClick={onOverlayClick} ref={overlayRef}>
          {template || defaultTemplate}
        </Root>,
        document.body,
      )
    : null;
};
