import { DEBT_AMOUNT_FLOOR } from '@entities/debt-form/constants';
import {
  moneyForOfferWallUrl,
  moneyForOfferWallUrlLessThen10000,
} from '@shared/api/constants';

export const getMoneyForRedirectUrl = (debtAmount: number): string => {
  const baseUrl =
    debtAmount <= DEBT_AMOUNT_FLOOR
      ? moneyForOfferWallUrlLessThen10000
      : moneyForOfferWallUrl;

  const url = new URL(baseUrl);
  url.search = new URLSearchParams({
    debt_amount: String(debtAmount),
  }).toString();

  return url.toString();
};
