'use client';

import styled from 'styled-components';
import { SectionTitle } from '@debt-relief/balanced-life-ui';
import { Button } from '@debt-relief/common-ui';
import { ReactComponent as GirlInstructorImageBase } from './images/girl-instructor.svg';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 45.1rem;
    margin: 0 auto;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Title = styled(SectionTitle)`
  text-align: left;
  color: ${({ theme }) => theme.palette.TypoNeutral100};
  max-width: 39.2rem;
  margin-bottom: 4rem;
  position: relative;
  min-height: 12rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 31.8rem;
    margin-bottom: 2.4rem;
  }
`;

export const TextHighlight = styled.span`
  color: ${({ theme }) => theme.palette.TypoPrimaryMain};
`;

export const GirlInstructorWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  width: 50%;
  min-width: 0;
  padding: 7.6rem 4.8rem;
  border-radius: 2.4rem;
  background: ${({ theme }) => theme.palette.Neutral75};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    order: 0;
    width: 100%;
    margin-bottom: 4rem;
    padding: 4.6rem 0;
  }
`;

export const GirlInstructorImage = styled(GirlInstructorImageBase)`
  display: block;
  width: 100%;
  height: auto;
  margin: auto;
`;

export const InstructionsBlock = styled.div`
  flex: 1 1 auto;
  width: 50%;
  min-width: 0;
  padding-right: 1.6rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    order: 1;
    width: 100%;
    padding-right: 0;
  }
`;

export const InstructionsList = styled.div`
  margin-bottom: 2.4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 4rem;
  }
`;

export const GetStartedButton = styled(Button)`
  margin-left: 3.6rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-left: 0;
    width: 100%;
  }
`;
