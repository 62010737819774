'use client';

import styled from 'styled-components';

export const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 120rem;
  display: flex;
  flex-wrap: nowrap;
  gap: 19.4704rem;
  align-items: stretch;
  transform: rotate(-24deg) translate(-50%, -65rem);
  transform-origin: left top;
  opacity: 0.1;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 8rem;
    height: 80rem;
    transform: rotate(-24deg) translate(-50%, -30rem);
  }
`;

export const Shaft = styled.div`
  flex: 0 0 auto;
  width: 12.5729rem;
  background: linear-gradient(
    180deg,
    #d9d9d9 31.77%,
    rgba(217, 217, 217, 0) 100%
  );
  mix-blend-mode: plus-lighter;
  filter: blur(68px);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 5.6rem;
    filter: blur(28px);
  }
`;
