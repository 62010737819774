import React from 'react';
import { type Instruction } from '../../types';
import {
  Root,
  InstructionBullet,
  InstructionContent,
  InstructionTitle,
  InstructionSubtitle,
} from './styles';

interface InstructionItemProps {
  instruction: Instruction;
  index: number;
}

export const InstructionItem: React.FC<InstructionItemProps> = ({
  instruction,
  index,
}) => (
  <Root>
    <InstructionBullet>{index}</InstructionBullet>

    <InstructionContent>
      <InstructionTitle>{instruction.title}</InstructionTitle>
      <InstructionSubtitle>{instruction.subtitle}</InstructionSubtitle>
    </InstructionContent>
  </Root>
);
