import { LinkRoot, ButtonRoot, Inner } from './styles';
import { type TButtonProps } from './types';

export const Button: React.FC<TButtonProps> = ({
  children,
  size = 'md',
  variant = 'fill',
  color = 'primary',
  href,
  ...props
}) => {
  const inner = <Inner>{children}</Inner>;

  if (href) {
    return (
      <LinkRoot
        {...props}
        $color={color}
        $size={size}
        $variant={variant}
        href={href}
      >
        {inner}
      </LinkRoot>
    );
  }

  return (
    <ButtonRoot
      {...props}
      $color={color}
      $size={size}
      $variant={variant}
      type="button"
    >
      {inner}
    </ButtonRoot>
  );
};
