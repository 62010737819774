'use client';

import styled from 'styled-components';
import { Link } from '@debt-relief/balanced-life-ui';
import { resolveTypographyVariant } from '@debt-relief/common-ui';

export const Title = styled.div(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.body.l.tight),
  fontWeight: 600,
  marginBottom: '0.8rem',
  color: theme.palette.TypoNeutral100,
}));

export const Subtitle = styled.div(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.body.l.paragraph),
  color: theme.palette.TypoNeutral100,
  maxHeight: '5.6rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: '1.6rem',
}));

export const ReadMoreLink = styled(Link)`
  font-weight: 600;
`;

export const ArticleImage = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 1.6rem;
  border-radius: 1.6rem;
`;
