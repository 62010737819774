import React from 'react';
import { Accordion, SunShafts } from '@debt-relief/balanced-life-ui';
import { Section } from '@debt-relief/common-ui';
import { FAQ_ACCORDION_OPTIONS } from '@entities/faq-questions/constants';
import { Root, Content, Title, Background } from './styles';

export const BalancedLifeFaq: React.FC = () => (
  <Root>
    <Background>
      <SunShafts count={20} />
    </Background>

    <Section paddings="lg">
      <Content>
        <Title>Top Questions People Ask Us</Title>

        <Accordion options={FAQ_ACCORDION_OPTIONS} />
      </Content>
    </Section>
  </Root>
);
