'use client';

import styled from 'styled-components';
import {
  resolveTypographyVariant,
  ExpandIcon as ExpandIconBase,
} from '@debt-relief/common-ui';

export const Item = styled.label`
  display: block;
  padding: 2.4rem;
  margin-bottom: 1.2rem;
  border-radius: 1.6rem;
  background-color: ${({ theme }) => theme.palette.FillNeutralL75};
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  & > input[type='checkbox'] {
    display: none;
  }
`;

export const ItemTitle = styled.div(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.headline.m),
  fontWeight: 600,
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '2.4rem',
  color: theme.palette.TypoNeutralContrast,
}));

export const ItemContent = styled.div<{
  $expanded: boolean;
}>(({ theme, $expanded }) => ({
  ...resolveTypographyVariant(theme.typography.body.l.paragraph),
  display: $expanded ? 'block' : 'none',
  paddingTop: '1.6rem',
  color: theme.palette.TypoNeutralContrast,
}));

export const ExpandIcon = styled(ExpandIconBase)<{
  $expanded: boolean;
}>`
  flex: 0 0 auto;
  width: 2.4rem;
  margin: 0.4rem;
  transform: rotate(${({ $expanded }) => ($expanded ? '45deg' : '0deg')});
  transition: transform ease 0.2s;
  color: ${({ theme }) => theme.palette.TypoNeutralContrast};
`;
