'use client';

import styled from 'styled-components';
import { Button, resolveTypographyVariant } from '@debt-relief/common-ui';
import { ReactComponent as OperatorImageBase } from './images/operator.svg';

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2.4rem;
  border-radius: 2.4rem;
  background: ${({ theme }) => theme.palette.TypoNeutralContrast};
  width: 43rem;
  box-sizing: border-box;
  transition: 300ms;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: calc(100% - 2rem);
  }
`;

export const ModalHeaderActions = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const CloseAction = styled(Button)`
  padding: 0.8rem;
  border-radius: 0.8rem;
  color: ${({ theme }) => theme.palette.TypoNeutral100};
  background-color: ${({ theme }) => theme.palette.Neutral75};
`;

export const OperatorImage = styled(OperatorImageBase)`
  display: block;
  height: auto;
  margin: 0 auto 1.2rem;

  @media (max-height: 425px) {
    display: none;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 100%;
  margin: 2.4rem 0 3.2rem;

  & > *:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  @media (max-height: 425px) {
    margin: 1.4rem 0;
  }
`;

export const Title = styled.h2(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.display.xs),
  color: theme.palette.TypoNeutral100,
  width: '100%',
  textAlign: 'center',
  margin: '0',
}));

export const Subtitle = styled.p(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.body.m.paragraph),
  fontWeight: 500,
  color: theme.palette.TypoNeutral100,
  width: '100%',
  textAlign: 'center',
  margin: '0',
}));

export const ModalActionsBlock = styled.div`
  margin: 0;

  & > * {
    width: 100%;
  }

  & > *:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;
