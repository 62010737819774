import React, { type FC } from 'react';
import { CloseIcon } from '@debt-relief/common-ui';
import { Modal } from '../modal';
import {
  CloseAction,
  ModalActionsBlock,
  ModalBody,
  ModalHeaderActions,
  ModalTitle,
  OperatorImage,
  Subtitle,
  Title,
} from './styles';
import type { TInactivityModalProps } from './types';

export const InactivityModal: FC<TInactivityModalProps> = ({
  onClose,
  isShown,
  title,
  subtitle,
  actions,
}) => {
  const template = (
    <ModalBody>
      <ModalHeaderActions>
        <CloseAction color="neutral" onClick={onClose} size="xs">
          <CloseIcon />
        </CloseAction>
      </ModalHeaderActions>

      <OperatorImage />

      {title || subtitle ? (
        <ModalTitle>
          {title ? <Title>{title}</Title> : null}
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        </ModalTitle>
      ) : null}

      {actions ? <ModalActionsBlock>{...actions}</ModalActionsBlock> : null}
    </ModalBody>
  );

  return <Modal isShown={isShown} onClose={onClose} template={template} />;
};
