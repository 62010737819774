import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';
import { formVersionBaseUrl } from '../constants';
import { formVersionApi } from './api';

export const formVersionClient = new Zodios(formVersionBaseUrl, formVersionApi);

export const formVersionHooks = new ZodiosHooks(
  'formVersion',
  formVersionClient,
);
