import { FormSteps, type TFormScriptBaseProps } from './types';
import { FormScriptBase } from './base';

type TFullFormScriptProps = Pick<
  TFormScriptBaseProps,
  'formBuildTag' | 'isModalForm' | 'modalFormMode'
>;

export const FullFormScript: React.FC<TFullFormScriptProps> = ({
  formBuildTag,
  isModalForm,
  modalFormMode,
}) => (
  <FormScriptBase
    formBuildTag={formBuildTag}
    isModalForm={isModalForm}
    modalFormMode={modalFormMode}
    steps={FormSteps.FullForm}
  />
);
