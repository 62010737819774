import { formApi } from '@shared/form-api';
import {
  redirectToMoneyFor,
  redirectByDebtAmount,
} from '@entities/debt-form/utils';
import { moneyForOfferWallUrl } from '@shared/api/constants';
import { MIN_ACCEPTABLE_DEBT_AMOUNT } from '@entities/debt-form/constants';

interface TopenFormOrRedirectProps {
  isFormMustBeOpen: boolean;
  isJgRedirect: boolean;
  debtAmount?: string;
}
export const openFormOrRedirect = ({
  isFormMustBeOpen,
  debtAmount,
  isJgRedirect,
}: TopenFormOrRedirectProps) => {
  if (isFormMustBeOpen) {
    formApi.openForm({
      values: { debtAmount },
    });
  } else if (typeof debtAmount !== 'undefined' || isJgRedirect) {
    redirectByDebtAmount({
      debtAmount: debtAmount ?? String(MIN_ACCEPTABLE_DEBT_AMOUNT),
      isJgRedirect,
    });
  } else {
    redirectToMoneyFor(moneyForOfferWallUrl);
  }
};
