import { type Article } from './types';
import settleCreditCardImage from './images/settle-credit-card.jpg';
import bestCompaniesImage from './images/best-companies.jpg';
import pathToDebtFreeImage from './images/path-to-debt-free.jpg';

export const ARTICLES: Article[] = [
  {
    id: 'settle_credit_card',
    title: 'How to Settle Credit Card Debt',
    subtitle:
      'If you have missed a lot of credit card payments and are watching your debt pile up it may feel like you’re never going to get out of credit card debt. But you do have options. From debt relief companies to credit counseling, there are ways to be debt free. One of these options is to settle your debt for less than you owe. While this can hurt your credit, it can do less damage than defaulting on your payments altogether and can help you get back on track. Before you decide to settle your debt, take a look at how the process works and see if it’s right for you.',
    link: 'https://moneyfor.com/blog/personal-finance/settle-credit-card-debt/',
    image: settleCreditCardImage,
  },
  {
    id: 'best_companies',
    title: 'Best Debt Relief Companies 2023',
    subtitle:
      'Debt consolidation loans help borrowers combine multiple high-interest debts into a single payment. Compare our picks for the best debt consolidation loans for all credit scores.',
    link: 'https://moneyfor.com/blog/personal-finance/3-options-pay-credit-card-debt/#busters',
    image: bestCompaniesImage,
  },
  {
    id: 'path_to_debt_free',
    title: 'Path to Debt-Free',
    subtitle:
      'Tailoring Solutions for Your Unique Financial Goals and Situation',
    link: 'https://moneyfor.com/blog/personal-finance/what-to-do-if-you-cant-pay-your-credit-card-bills/',
    image: pathToDebtFreeImage,
  },
];
