'use client';

import React, { useState } from 'react';
import { Item, ItemContent, ItemTitle, ExpandIcon } from './styles';
import { type TAccordionItemProps, type TAccordionProps } from './types';

export const AccordionItem: React.FC<TAccordionItemProps> = ({
  index,
  title,
  content,
  expanded,
  onToggle,
}) => {
  const itemId = `accordion-item-${index}`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onToggle(event.target.checked, index);
  };

  return (
    <Item htmlFor={itemId}>
      <input
        checked={expanded}
        id={itemId}
        onChange={handleChange}
        type="checkbox"
      />

      <ItemTitle className="accordion-item__title">
        {title}

        <ExpandIcon $expanded={expanded} />
      </ItemTitle>

      <ItemContent $expanded={expanded}>{content}</ItemContent>
    </Item>
  );
};

export const Accordion: React.FC<TAccordionProps> = ({ options }) => {
  const [expandedMap, setExpandedMap] = useState<Record<number, boolean>>({});

  const handleItemToggle = (expanded: boolean, index: number) => {
    setExpandedMap((prevExpandedMap) => ({
      ...prevExpandedMap,
      [index]: expanded,
    }));
  };

  return (
    <div>
      {options.map((option, index) => (
        <AccordionItem
          content={option.content}
          expanded={Boolean(expandedMap[index])}
          index={index}
          key={option.title}
          onToggle={handleItemToggle}
          title={option.title}
        />
      ))}
    </div>
  );
};
