export type TFormBuildName = 'zen';

export const enum FormSteps {
  FullForm = 'fullForm',
}

export type TFormConfigModalFormMode = 'backdrop' | 'fullbody';

// eslint-disable-next-line @typescript-eslint/no-empty-interface -- Ignore empty interface for future changes
export interface TFormConfigAvailableFeatures {}

export interface TFormConfig {
  steps?: FormSteps;
  isModalForm?: boolean;
  modalFormMode?: TFormConfigModalFormMode;
  landingBuildTag?: string;
  enabledFeatures?: Partial<TFormConfigAvailableFeatures>;
}

declare global {
  interface Window {
    _debt_form_config_?: TFormConfig;
  }
}

export interface TFormScriptBaseProps {
  steps: FormSteps;
  formBuildTag: string;
  isModalForm?: boolean;
  modalFormMode?: TFormConfigModalFormMode;
}
