import Script from 'next/script';
import { getBuildTag } from '@shared/utils/env';
import { type TFormConfig, type TFormScriptBaseProps } from './types';
import { getFormScriptSrc } from './utils/get-form-script-src';

export const FormScriptBase: React.FC<TFormScriptBaseProps> = ({
  formBuildTag,
  steps,
  isModalForm = false,
  modalFormMode = 'fullbody',
}) => {
  const config: TFormConfig = {
    steps,
    isModalForm,
    modalFormMode,
    landingBuildTag: getBuildTag(),
  };

  const src = getFormScriptSrc({
    buildName: 'zen',
    buildTag: formBuildTag,
  });

  return (
    <>
      <Script id="_debt_form_config_script_">
        {`window._debt_form_config_ = ${JSON.stringify(config)};`}
      </Script>

      <div id="pr-lead-form-v3" />

      <Script src={src} />
    </>
  );
};
