'use client';

import React from 'react';
import { FieldContent, Label } from './styles';

interface TFormFieldProps {
  className?: string;
  children: React.ReactNode;
  label: React.ReactNode;
}

export const FormField: React.FC<TFormFieldProps> = ({
  className,
  children,
  label,
}) => {
  return (
    <div className={className}>
      <Label>{label}</Label>
      <FieldContent>{children}</FieldContent>
    </div>
  );
};
