import { times } from '@debt-relief/utils';
import { Root, Shaft } from './styles';

export interface TSunShaftsProps {
  className?: string;
  count: number;
}

export const SunShafts: React.FC<TSunShaftsProps> = ({ className, count }) => (
  <Root className={className}>
    {times(count, (index) => (
      <Shaft key={`sun-shaft-${index}`} />
    ))}
  </Root>
);
