import { makeApi } from '@zodios/core';
import { formVersionResponseSchema } from './schemas';

export const formVersionApi = makeApi([
  {
    alias: 'getVersion',
    method: 'get',
    path: '/form/version/',
    response: formVersionResponseSchema,
  },
]);
