import { FooterSection } from '@debt-relief/balanced-life-ui';
import { getCustomerServiceEmail, getFooterLinks } from '@shared/utils/content';

const disclaimerText =
  'We make every effort to ensure the accuracy and timeliness of the\n' +
  'information we provide. However, the information you see when you visit\n' +
  'a financial institution, service provider, or product site may differ\n' +
  'from ours. We present all financial products, shopping products, and\n' +
  'services without warranty. It’s important to review the terms and\n' +
  'conditions of any financial institution before making a decision. Please\n' +
  'note that pre-qualified offers are not legally binding.';

export const BalancedLifeFooter: React.FC = () => {
  const footerLinks = getFooterLinks();

  return (
    <FooterSection
      customerServiceEmail={getCustomerServiceEmail()}
      disclaimerText={disclaimerText}
      links={footerLinks}
    />
  );
};
