'use client';

import React, { useEffect } from 'react';
import { Button, GlobalStyles, ThemeProvider } from '@debt-relief/common-ui';
import { InactivityModal } from '@debt-relief/balanced-life-ui/src/inactivity-modal';
import { CallIcon } from '@debt-relief/balanced-life-ui/src/top-bar/styles';
import { gtag, getContactPhone } from '@debt-relief/utils';
import { useUsStateByIp } from '@shared/hooks/use-us-state-by-ip';
import { useInactivityModalState } from '@entities/inactivity-modal';
import { BalancedLifeFaq } from '@features/balanced-life-faq';
import { BalancedLifeArticles } from '@features/balanced-life-articles';
import { BalancedLifeInstructions } from '@features/balanced-life-instructions';
import { BalancedLifeHeader } from '@features/balanced-life-header';
import { BalancedLifeFooter } from '@features/balanced-life-footer';
import { useDebtForm } from '@entities/debt-form/hooks/use-debt-form';
import { TextHighlight } from '@features/balanced-life-instructions/styles';
import { useAnalytics } from '@shared/analytics';
import { FullFormScript } from '@features/form-script/full-form';
import { useFormVersion } from '@shared/hooks/use-form-version';
import { openFormOrRedirect } from '@shared/utils/common';
import { isFormOn, isJgRedirectOn } from '@shared/utils/env';

const contactPhone = getContactPhone();

const InactivityModalTitle: React.FC = () => (
  <span>
    <span>Reduce Your Credit Card</span>
    <br />
    <span>
      Debt By <TextHighlight>Up To Half</TextHighlight>
    </span>
  </span>
);

const inactivityModalSubtitle = 'Speak to a debt specialist for free';

const MainPage: React.FC = () => {
  const analytics = useAnalytics();
  const formBuildTag = useFormVersion();
  const usState = useUsStateByIp();

  const { debtAmount, updateDebtAmount } = useDebtForm();

  const { opened: inactivityModalOpened, close: closeInactivityModal } =
    useInactivityModalState();

  useEffect(() => {
    if (inactivityModalOpened) {
      gtag({ event: 'inactivity_modal_opened' });
      void analytics.trackEvent({
        type: 'showInactivityModal',
        payload: {},
      });
    }
  }, [analytics, inactivityModalOpened]);

  const handleCallClick = (): void => {
    gtag({ event: 'click_to_call' });

    void analytics.trackEvent(
      {
        type: 'clickToCall',
        payload: {
          referrer: 'inactivityDialog',
        },
      },
      {
        instant: true,
      },
    );

    closeInactivityModal();
  };

  const handleCompleteFormClick = (): void => {
    closeInactivityModal();

    void analytics.trackEvent(
      {
        type: 'goToForm',
        payload: {
          referrer: 'modal',
        },
      },
      {
        instant: true,
      },
    );
    openFormOrRedirect({
      isFormMustBeOpen: isFormOn(),
      debtAmount,
      isJgRedirect: isJgRedirectOn(),
    });
  };

  return (
    <ThemeProvider>
      <GlobalStyles />

      <BalancedLifeHeader
        debtAmount={debtAmount}
        updateDebtAmount={updateDebtAmount}
        usState={usState}
      />
      <FullFormScript formBuildTag={formBuildTag} isModalForm />

      <BalancedLifeInstructions debtAmount={debtAmount} usState={usState} />

      <BalancedLifeArticles />

      <BalancedLifeFaq />

      <BalancedLifeFooter />

      <InactivityModal
        actions={[
          <Button
            color="accent"
            href={contactPhone.href}
            key="phone"
            onClick={handleCallClick}
            size="lg"
          >
            <CallIcon />
            {contactPhone.label}
          </Button>,
          <Button
            color="neutral"
            key="completeForm"
            onClick={handleCompleteFormClick}
            size="xs"
            variant="text"
          >
            Or Complete This Form
          </Button>,
        ]}
        isShown={inactivityModalOpened}
        onClose={closeInactivityModal}
        subtitle={inactivityModalSubtitle}
        title={<InactivityModalTitle />}
      />
    </ThemeProvider>
  );
};

export default MainPage;
