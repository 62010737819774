'use client';

import styled from 'styled-components';
import { Button } from '@debt-relief/common-ui';
import type { TRootProps } from './types';

export const Root = styled.div<TRootProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  background: rgba(19, 29, 24, 0.64); // todo Replace with palette color later
  z-index: 9999;
  transition: 300ms;
  opacity: ${({ $isShown }) => ($isShown ? 1 : 0)};
  visibility: ${({ $isShown }) => ($isShown ? 'visible' : 'hidden')};
`;

export const ModalMain = styled.div`
  position: relative;
  width: 100%;
  padding: 2.4rem;
  transition: 300ms;
  border-radius: 2.4rem;
  background: ${({ theme }) => theme.palette.TypoNeutralContrast};
  max-width: 43rem;
  box-sizing: border-box;
  margin: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    position: absolute;
    bottom: 0;
    max-width: unset;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }
`;

export const ModalBody = styled.div`
  margin: 2rem 0 0;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const ModalHeaderTitleBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 100%;
`;

export const ModalHeaderCloseActionBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
`;

export const ModalTitle = styled.h2`
  margin: 0;
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.TypoNeutral100};
`;

export const ModalSubtitle = styled.p`
  margin: 0.8rem 0 0;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.4;
  color: ${({ theme }) => theme.palette.TypoNeutral100};
`;

export const ModalActionsBlock = styled.div`
  margin: 2rem 0 0;

  & > * {
    width: 100%;
  }
`;

export const ModalActionButton = styled(Button)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  padding: 1.4rem 3.2rem;
  border-radius: 0.4rem;

  & > *:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const ModalCloseAction = styled(Button)`
  padding: 0;
  color: ${({ theme }) => theme.palette.TypoPrimaryMain};
`;
