'use client';

import styled from 'styled-components';
import { resolveTypographyVariant } from '@debt-relief/common-ui';

export const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
`;

export const NativeInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`;

interface TOptionProps {
  $selected: boolean;
}

export const Option = styled.label<TOptionProps>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 1.2rem 1.6rem;
  gap: 1.2rem;
  border-radius: 1.2rem;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.Neutral75};
  box-shadow:
    0 0 0 0.2rem
      ${({ theme, $selected }) =>
        $selected ? theme.palette.TypoNeutralContrast : 'transparent'},
    0 0 0 0.3rem
      ${({ theme, $selected }) =>
        $selected ? theme.palette.FillPrimaryMain : 'transparent'};

  &:hover {
    background-color: ${({ theme, $selected }) =>
      $selected ? theme.palette.Neutral75 : theme.palette.Neutral125};
    box-shadow:
      0 0 0 0.2rem ${({ theme }) => theme.palette.TypoNeutralContrast},
      0 0 0 0.3rem
        ${({ theme, $selected }) =>
          $selected ? theme.palette.FillPrimaryD100 : theme.palette.Neutral125};
  }

  &:active {
    background-color: ${({ theme, $selected }) =>
      $selected ? theme.palette.Neutral75 : theme.palette.Neutral175};
    box-shadow:
      0 0 0 0.2rem ${({ theme }) => theme.palette.TypoNeutralContrast},
      0 0 0 0.3rem
        ${({ theme, $selected }) =>
          $selected ? theme.palette.FillPrimaryD200 : theme.palette.Neutral175};
  }
`;

export const OptionTitle = styled.span(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.body.m.paragraph),
  fontWeight: 500,
  color: theme.palette.TypoNeutral100,
}));

interface TOptionBulletProps {
  $selected: boolean;
}

export const OptionBullet = styled.div<TOptionBulletProps>`
  --bullet-container-size: 1.4rem;

  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: var(--bullet-container-size);
  height: var(--bullet-container-size);
  border: 0.1rem solid;
  border-color: ${({ theme, $selected }) =>
    $selected ? theme.palette.FillPrimaryMain : theme.palette.TypoNeutral100};
  border-radius: 50%;
  background-color: transparent;
  position: relative;

  &::before {
    --bullet-size: calc(var(--bullet-container-size) / 2);

    display: ${({ $selected }) => ($selected ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: var(--bullet-size);
    height: var(--bullet-size);
    background-color: ${({ theme }) => theme.palette.FillPrimaryMain};
    border-radius: 50%;
  }
`;
