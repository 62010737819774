import React from 'react';
import { Section } from '@debt-relief/common-ui';
import { Link } from '../link';
import { type TFooterProps } from './types';
import {
  Root,
  Disclaimer,
  Links,
  LinksItem,
  CustomerService,
  CustomerServiceLabel,
} from './styles';

export const FooterSection: React.FC<TFooterProps> = ({
  customerServiceEmail,
  links,
  disclaimerText,
}) => (
  <Root>
    <Section paddings="md">
      <Links>
        {links.map((link) => (
          <LinksItem key={link.href}>
            <Link
              color="primary"
              href={link.href}
              target="_blank"
              underline="unhover"
            >
              {link.text}
            </Link>
          </LinksItem>
        ))}

        {Boolean(customerServiceEmail) && (
          <CustomerService>
            <CustomerServiceLabel>Customer Service: </CustomerServiceLabel>

            <Link
              color="primary"
              href={`mailto:${customerServiceEmail}`}
              underline="unhover"
            >
              {customerServiceEmail}
            </Link>
          </CustomerService>
        )}
      </Links>

      <Disclaimer>{disclaimerText}</Disclaimer>
    </Section>
  </Root>
);
