import { isDevelopmentMode } from '@shared/utils/env';
import { type TFormBuildName } from '../types';

interface TGetFormScriptSrcParams {
  buildName: TFormBuildName;
  buildTag: string;
}

export const getFormScriptSrc = ({
  buildName,
  buildTag,
}: TGetFormScriptSrcParams): string =>
  `/form/${isDevelopmentMode() ? 'app' : buildName}.js?bt=${buildTag}`;
