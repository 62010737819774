import React from 'react';
import { NativeInput, Option, OptionBullet, OptionTitle, Root } from './style';
import { type TOptionGroupProps } from './types';

export const OptionGroup: React.FC<TOptionGroupProps> = ({
  value,
  name,
  options,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
  };

  return (
    <Root>
      {options.map((option) => (
        <Option
          $selected={option.value === value}
          htmlFor={`${name}-${option.value}`}
          key={option.value}
        >
          <OptionBullet $selected={option.value === value} />

          <OptionTitle>{option.label}</OptionTitle>

          <NativeInput
            id={`${name}-${option.value}`}
            name={name}
            onChange={handleChange}
            type="radio"
            value={option.value}
          />
        </Option>
      ))}
    </Root>
  );
};
