'use client';

import styled, { css } from 'styled-components';

export const Phrase = styled.span<{
  $hidden: boolean;
}>`
  ${(props) =>
    props.$hidden &&
    css`
      display: none;
    `}
`;

const baseFadeAnimationStyles = css`
  transform-origin: center top;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.8, 0, 0.2, 1);
  animation-fill-mode: forwards;
`;

export const Word = styled.div<{
  $fadeIn: boolean;
  $fadeOut: boolean;
  $hidden: boolean;
  $animationDelay: number;
}>`
  display: inline-block;

  opacity: ${({ $hidden }) => ($hidden ? '0' : '1')};
  animation-delay: ${({ $animationDelay }) => `${$animationDelay}ms`};
  will-change: transform, opacity;

  ${({ $fadeIn }) =>
    $fadeIn &&
    css`
      ${baseFadeAnimationStyles};
      animation-name: show;
    `}

  ${({ $fadeOut }) =>
    $fadeOut &&
    css`
      ${baseFadeAnimationStyles};
      animation-name: hide;
    `}

  @keyframes show {
    0% {
      transform: translate3d(0, 1em, 0);
      opacity: 0;
    }

    90% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes hide {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    100% {
      transform: translate3d(0, -1em, 0);
      opacity: 0;
    }
  }
`;
