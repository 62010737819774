'use client';

import React, { useMemo } from 'react';
import { NativeSelect, Root, SelectValue, ChevronDownIcon } from './style';
import { type TSelectOwnProps } from './types';

type TSelectProps = Omit<
  React.HTMLProps<HTMLSelectElement>,
  keyof TSelectOwnProps
> &
  TSelectOwnProps;

export const Select: React.FC<TSelectProps> = ({
  name,
  id,
  value,
  options,
  onChange,
  ...props
}) => {
  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value],
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    onChange(event.target.value);
  };

  return (
    <Root htmlFor={id}>
      <SelectValue>{selectedOption?.label || ''}</SelectValue>

      <NativeSelect
        id={id}
        name={name}
        onChange={handleChange}
        value={value}
        {...props}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>

      <ChevronDownIcon />
    </Root>
  );
};
