import { forwardRef } from 'react';
import { Root } from './styles';

export const CloseIcon = forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => (
  <Root
    {...props}
    fill="none"
    height="24"
    ref={ref}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.466 12.0001L19.6962 5.76972C20.1017 5.36446 20.1017 4.70921 19.6962 4.30395C19.291 3.89868 18.6357 3.89868 18.2305 4.30395L12.0001 10.5343L5.76986 4.30395C5.36441 3.89868 4.70935 3.89868 4.30409 4.30395C3.89864 4.70921 3.89864 5.36446 4.30409 5.76972L10.5343 12.0001L4.30409 18.2305C3.89864 18.6358 3.89864 19.291 4.30409 19.6963C4.50605 19.8984 4.77161 20 5.03698 20C5.30234 20 5.56771 19.8984 5.76986 19.6963L12.0001 13.4659L18.2305 19.6963C18.4326 19.8984 18.698 20 18.9634 20C19.2287 20 19.4941 19.8984 19.6962 19.6963C20.1017 19.291 20.1017 18.6358 19.6962 18.2305L13.466 12.0001Z"
      fill="currentColor"
    />
  </Root>
));

CloseIcon.displayName = 'CloseIcon';
