'use client';

import styled from 'styled-components';
import { SectionTitle } from '@debt-relief/balanced-life-ui';

export const Root = styled.div`
  position: relative;
`;

export const Content = styled.div`
  max-width: 76.8rem;
  margin: 0 auto;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: radial-gradient(62.61% 55.79% at 50% 0%, #0d462a 0%, #05170b 100%),
    #05170b;
  pointer-events: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    background: radial-gradient(
      128.58% 36.8% at 50% 0%,
      #0d462a 0%,
      #05170b 100%
    );
  }
`;

export const Title = styled(SectionTitle)`
  text-align: center;
  margin-bottom: 6.4rem;
  color: ${({ theme }) => theme.palette.TypoNeutralContrast};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 4rem;
  }
`;
