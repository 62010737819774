import { ipInfoHooks } from '@shared/api/ip-info';
import { logger } from '@shared/logger';

export const useUsStateByIp = (): string | undefined => {
  const { data, error, isError } = ipInfoHooks.useGetIpInfo();

  if (isError) {
    logger.error(`Ip info request failed: ${(error as Error).message}`);
    return undefined;
  }

  if (data && data.countryCode === 'US' && data.countryRegionCode) {
    return data.countryRegionCode;
  }

  return undefined;
};
