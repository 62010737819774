'use client';

import styled from 'styled-components';

export const Root = styled.svg`
  color: ${({ theme }) => theme.palette.TypoNeutralContrast};
`;

export const AccentPath = styled.path`
  color: ${({ theme }) => theme.palette.TypoAccentL200};
`;
