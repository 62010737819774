import {
  getClickIdFromQuery,
  getUrlParam,
  getUrlParams,
} from '@debt-relief/utils';
import { getSessionId } from '@debt-relief/session-id';

export const redirectToMoneyFor = (
  baseUrl: string,
  additionalParams?: Record<string, string>,
): void => {
  const params = new URLSearchParams();

  const sessionId = getSessionId();
  if (sessionId) {
    params.append('subId1', sessionId);
  }

  const clickId = getClickIdFromQuery();
  if (clickId) {
    params.append('subId2', clickId);
  }

  const utmSource = getUrlParam('utm_source');
  if (utmSource) {
    params.append('source', `debtr_${utmSource}`);
  }

  const urlParams = getUrlParams();
  for (const paramName of Object.keys(urlParams)) {
    if (paramName.startsWith('utm_')) {
      params.append(paramName, urlParams[paramName]);
    }
  }

  if (additionalParams) {
    for (const paramName of Object.keys(additionalParams)) {
      params.append(paramName, additionalParams[paramName]);
    }
  }

  window.location.assign(`${baseUrl}?${params.toString()}`);
};
