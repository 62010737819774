'use client';

import styled from 'styled-components';
import { type TLinkProps } from './types';

const excludedProps = new Set<keyof TLinkProps>([
  'color',
  'hoverColor',
  'underline',
]);

export const Link = styled.a
  .withConfig({
    shouldForwardProp: (prop) => !excludedProps.has(prop as keyof TLinkProps),
  })
  .attrs<TLinkProps>(
    ({ color = 'inherit', hoverColor = color, underline = 'hover' }) => ({
      color,
      hoverColor,
      underline,
    }),
  )<TLinkProps>(({ theme, color, underline, hoverColor }) => ({
  padding: 0,
  margin: 0,
  fontFamily: 'inherit',
  cursor: 'pointer',
  textDecoration: 'none',
  border: 0,
  backgroundColor: 'transparent',
  WebkitTapHighlightColor: 'transparent',
  appearance: 'none',
  textUnderlineOffset: '0.2rem',
  fontWeight: '400',

  ...(color === 'inherit' && {
    color: 'inherit',
  }),

  ...(color === 'primary' && {
    color: theme.palette.TypoPrimaryMain,
  }),

  ...(underline === 'unhover' && {
    textDecoration: 'underline',
  }),

  ...(underline === 'always' && {
    textDecoration: 'underline',
  }),

  '&:hover': {
    ...(hoverColor === 'inherit' && {
      color: 'inherit',
    }),

    ...(hoverColor === 'primary' && {
      color: theme.palette.TypoPrimaryMain,
    }),

    ...(underline === 'hover' && {
      textDecoration: 'underline',
    }),

    ...(underline === 'unhover' && {
      textDecoration: 'none',
    }),

    ...(underline === 'always' && {
      textDecoration: 'underline',
    }),
  },
}));
