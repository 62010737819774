import { makeApi } from '@zodios/core';
import { ipInfoResponseSchema } from '@shared/api/ip-info/schemas';

export const ipInfoApi = makeApi([
  {
    alias: 'getIpInfo',
    method: 'get',
    path: '/databank/ip-info/',
    response: ipInfoResponseSchema,
  },
]);
