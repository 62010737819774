import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

export const useVisibility = ({
  threshold,
}: {
  threshold: number;
}): [RefObject<HTMLDivElement>, boolean] => {
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        threshold,
      },
    );

    const elementToObserve = ref.current;

    if (elementToObserve) {
      observer.observe(elementToObserve);
    }

    return () => {
      if (elementToObserve) {
        observer.unobserve(elementToObserve);
      }
    };
  }, [threshold]);

  return [ref, isVisible];
};
