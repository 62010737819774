import { API_CHECK_INTERVAL, API_WAITING_TIMEOUT } from '../constants';
import { getGlobalApi } from './get-global-api';

let globalApiPromise: Promise<void> | undefined;

export const waitGlobalApi = (): Promise<void> => {
  if (!globalApiPromise) {
    globalApiPromise = new Promise<void>((resolve, reject) => {
      let rejectTimeoutId = 0;
      let resolveIntervalId = 0;

      const clear = (): void => {
        window.clearTimeout(rejectTimeoutId);
        window.clearInterval(resolveIntervalId);
      };

      rejectTimeoutId = window.setTimeout(() => {
        clear();
        reject(
          new Error(
            `formApi waiting timeout exceeded (${API_WAITING_TIMEOUT}ms)`,
          ),
        );
      }, API_WAITING_TIMEOUT);

      resolveIntervalId = window.setInterval(() => {
        if (getGlobalApi()) {
          clear();
          resolve();
        }
      }, API_CHECK_INTERVAL);
    });
  }

  return globalApiPromise;
};
