import { type TFooterLink } from '@debt-relief/balanced-life-ui';
import { getContactFormUrl } from '@shared/utils/content/get-contact-form-url';

export const getFooterLinks = (): TFooterLink[] => [
  {
    href: '/page/privacy-policy',
    text: 'Privacy Policy',
  },
  {
    href: '/page/terms-of-use',
    text: 'Terms Of Use',
  },
  {
    href: getContactFormUrl(),
    text: 'Contact',
  },
];
