'use client';

import styled from 'styled-components';
import { resolveTypographyVariant } from '@debt-relief/common-ui';

export const FieldContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.4rem;
  border-radius: 1.2rem;
  box-shadow:
    0 0 0 0.2rem ${({ theme }) => theme.palette.TypoNeutralContrast},
    0 0 0 0.3rem ${({ theme }) => theme.palette.FillPrimaryMain};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-flow: column nowrap;
    gap: 0.8rem;
    box-shadow: none;
  }
`;

export const Label = styled.label(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.body.m.paragraph),
  fontWeight: 500,
  display: 'block',
  color: theme.palette.TypoNeutral200,
  marginBottom: '1.2rem',
}));
