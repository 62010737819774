import { CallButton, CallIcon, Logo, Root } from './styles';
import type { TTopBarProps } from './types';

export const TopBar: React.FC<TTopBarProps> = ({
  contactPhone,
  onCallClick,
}) => (
  <Root>
    <Logo />

    <CallButton color="accent" href={contactPhone.href} onClick={onCallClick}>
      <CallIcon />
      {contactPhone.label}
    </CallButton>
  </Root>
);
