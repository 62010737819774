import { type TAccordionOption } from './types';

export const FAQ_ACCORDION_OPTIONS: TAccordionOption[] = [
  {
    title: 'What types of debt relief do your partners offer?',
    content:
      'Our partners provide a range of services, including debt consolidation, debt settlement, and credit counseling. Each company specializes in a different approach to help you address and manage your unique financial situation. Their negotiators work with your creditors directly to reduce your debt balance.',
  },
  {
    title: 'How do I choose the best debt relief option for me?',
    content:
      'Choosing the right debt relief option depends on various factors such as the type and amount of your debt, your financial goals, and your ability to make payments. Our partners will assess your situation and provide personalized plans based on your specific needs. We recommend you explore all programs our partners offer and compare options and fees to make an informed decision.',
  },
  {
    title: 'How do I know if I qualify for debt relief?',
    content:
      'Qualifying for debt relief depends on your total debt amount, income, and financial hardship. Our partners will carefully evaluate your financial situation when providing you with personalized debt relief options. Generally, individuals with a significant amount of unsecured debt will qualify for debt relief.',
  },
  {
    title: 'Will participating in debt relief affect my credit score?',
    content:
      'Enrolling in a debt relief program may have an impact on your credit score. However, the extent of the impact will vary based on the specific debt relief option you choose. While your credit score may be affected in the short term, successfully completing a debt relief program can contribute to long-term financial recovery. It’s essential to discuss potential credit implications with the debt relief company to fully understand the effects on your creditworthiness.',
  },
];
