import { forwardRef } from 'react';
import { Icon } from '../icon';

export const ArrowRightIcon = forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => (
  <Icon
    {...props}
    fill="none"
    height="17"
    ref={ref}
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.86811 13.7398C9.8697 13.7382 9.87126 13.7366 9.87285 13.735L14.7737 8.84061C14.8124 8.80172 14.8467 8.75875 14.8762 8.71247L14.9531 8.57154L14.9851 8.47545V8.40497C15.005 8.29698 15.005 8.18626 14.9851 8.07824V8.01419V7.93731L14.9275 7.8284C14.8945 7.76771 14.8536 7.71167 14.8057 7.66182L9.87285 2.74818C9.54643 2.41914 9.01505 2.41701 8.68602 2.74343C8.68443 2.74499 8.68286 2.74658 8.68127 2.74818C8.36482 3.07985 8.36482 3.60165 8.68127 3.93336L11.6026 6.86105C11.7264 6.98735 11.7244 7.19017 11.5981 7.31402C11.5393 7.37164 11.4606 7.40444 11.3783 7.40558H1.8393C1.37579 7.40555 1.00003 7.78125 1 8.24476C0.999969 8.70827 1.37567 9.084 1.83918 9.08406H1.8393H11.3783C11.5552 9.08655 11.6966 9.23196 11.6941 9.40883C11.693 9.49111 11.6602 9.56982 11.6026 9.62859L8.68127 12.5435C8.36074 12.876 8.36074 13.4025 8.68127 13.7351C9.0077 14.0641 9.53907 14.0662 9.86811 13.7398Z"
      fill="currentColor"
    />
  </Icon>
));

ArrowRightIcon.displayName = 'ArrowRightIcon';
