'use client';

import React from 'react';
import { Section } from '@debt-relief/common-ui';
import { useAnalytics } from '@shared/analytics';
import { INSTRUCTIONS } from '@entities/instructions/constants';
import { InstructionItem } from '@entities/instructions/components/instruction-item';
import { AnimatedByWordsText } from '@shared/components/animated-text';
import { useVisibility } from '@shared/hooks/use-visibility';
import { isFormOn, isJgRedirectOn } from '@shared/utils/env';
import { openFormOrRedirect } from '@shared/utils/common';
import { useInactivityModalState } from '@entities/inactivity-modal';
import {
  Container,
  GetStartedButton,
  GirlInstructorImage,
  GirlInstructorWrapper,
  InstructionsBlock,
  InstructionsList,
  TextHighlight,
  Title,
} from './styles';

interface TBalancedLifeInstructionsProps {
  debtAmount: string;
  usState?: string;
}

export const BalancedLifeInstructions: React.FC<
  TBalancedLifeInstructionsProps
> = ({ debtAmount }) => {
  const analytics = useAnalytics();
  const { close: closeInactivityModal } = useInactivityModalState();

  const handleGetStartedClick = (): void => {
    closeInactivityModal();
    void analytics.trackEvent(
      {
        type: 'goToForm',
        payload: {
          referrer: 'instructions',
        },
      },
      {
        instant: true,
      },
    );
    openFormOrRedirect({
      isFormMustBeOpen: isFormOn(),
      debtAmount,
      isJgRedirect: isJgRedirectOn(),
    });
  };

  const [sectionRef, isSectionVisible] = useVisibility({ threshold: 0.5 });

  return (
    <Section paddings="lg">
      <Container>
        <InstructionsBlock>
          <Title>
            <span>How to&nbsp;</span>

            <TextHighlight>
              <AnimatedByWordsText
                active={isSectionVisible}
                delayBetweenWords={50}
                phrases={['take back your Life', 'get rid of debt']}
              />
            </TextHighlight>
          </Title>

          <InstructionsList ref={sectionRef}>
            {INSTRUCTIONS.map((instruction, index) => (
              <InstructionItem
                index={index + 1}
                instruction={instruction}
                key={instruction.id}
              />
            ))}
          </InstructionsList>

          <GetStartedButton
            color="accent"
            onClick={handleGetStartedClick}
            size="lg"
          >
            Get Started
          </GetStartedButton>
        </InstructionsBlock>

        <GirlInstructorWrapper>
          <GirlInstructorImage />
        </GirlInstructorWrapper>
      </Container>
    </Section>
  );
};
