import { type Instruction } from './types';

export const INSTRUCTIONS: Instruction[] = [
  {
    id: 'quick_application',
    title: 'Complete a quick application',
    subtitle: 'Complete our fast, secure online form in just 2 minutes',
  },
  {
    id: 'personal_plan',
    title: 'Get personal plan from our partners',
    subtitle: 'Explore all your debt relief options – no obligation.',
  },
  {
    id: 'best_option',
    title: 'Choose the best option for you',
    subtitle:
      'Select and approve a debt relief plan that fits your financial situation and goals.',
  },
];
