'use client';

import React from 'react';
import { gtag, getContactPhone } from '@debt-relief/utils';
import { TopBar } from '@debt-relief/balanced-life-ui';
import { Section } from '@debt-relief/common-ui';
import { useAnalytics } from '@shared/analytics';
import { isFormOn, isJgRedirectOn } from '@shared/utils/env';
import { openFormOrRedirect } from '@shared/utils/common';
import { useInactivityModalState } from '@entities/inactivity-modal';
import {
  Background,
  DebtForm,
  GirlImage,
  HeroContent,
  HeroSection,
  HeroTitle,
  HeroTitleHighlight,
  Root,
  SunShafts,
} from './style';

const contactPhone = getContactPhone();

interface TBalancedLifeHeaderProps {
  debtAmount: string;
  updateDebtAmount: (newDebtAmount: string) => void;
  usState?: string;
}

export const BalancedLifeHeader: React.FC<TBalancedLifeHeaderProps> = ({
  debtAmount,
  updateDebtAmount,
}) => {
  const analytics = useAnalytics();
  const { close: closeInactivityModal } = useInactivityModalState();

  const handleCallClick = (): void => {
    gtag({ event: 'click_to_call' });

    void analytics.trackEvent(
      {
        type: 'clickToCall',
        payload: {
          referrer: 'header',
        },
      },
      {
        instant: true,
      },
    );
  };

  const handleSubmit = (): void => {
    closeInactivityModal();
    void analytics.trackEvent(
      {
        type: 'goToForm',
        payload: {
          referrer: 'header',
        },
      },
      {
        instant: true,
      },
    );

    openFormOrRedirect({
      isFormMustBeOpen: isFormOn(),
      debtAmount,
      isJgRedirect: isJgRedirectOn(),
    });
  };

  return (
    <Root>
      <Background>
        <GirlImage />
        <SunShafts count={20} />
      </Background>

      <Section>
        <TopBar contactPhone={contactPhone} onCallClick={handleCallClick} />
      </Section>

      <HeroSection>
        <HeroContent>
          <HeroTitle>
            Envision a&nbsp;Balanced Life,{' '}
            <HeroTitleHighlight>Free from Debt</HeroTitleHighlight>
          </HeroTitle>

          <DebtForm
            debtAmount={debtAmount}
            onSubmit={handleSubmit}
            updateDebtAmount={updateDebtAmount}
          />
        </HeroContent>
      </HeroSection>
    </Root>
  );
};
