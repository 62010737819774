'use client';

import styled from 'styled-components';
import { resolveTypographyVariant } from '@debt-relief/common-ui';

export const Root = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.2rem 0;
  gap: 1.2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const InstructionBullet = styled.div`
  flex: 0 0 auto;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0.2rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.Primary50};
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: -0.028rem;
  color: ${({ theme }) => theme.palette.TypoPrimaryMain};
`;

export const InstructionContent = styled.div`
  flex: 1 1 auto;
  color: rgba(4, 41, 22, 0.88);
`;

export const InstructionTitle = styled.div(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.body.l.tight),
  fontWeight: 600,
  marginBottom: '0.4rem',
}));

export const InstructionSubtitle = styled.div(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.body.l.paragraph),
}));
