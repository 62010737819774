'use client';

import styled from 'styled-components';

export const Root = styled.footer`
  background: radial-gradient(
      82.88% 83.95% at 50% 100%,
      #0d462a 0%,
      #05170b 71.05%
    ),
    #05170b;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    background: radial-gradient(
      134.31% 68.19% at 50% 100%,
      #0d462a 0%,
      #05170b 100%
    );
  }
`;

export const Disclaimer = styled.div`
  color: ${({ theme }) => theme.palette.TypoNeutral500};
`;

export const Links = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 8rem;
  padding: 4.8rem 0;
  border-top: 0.1rem solid ${({ theme }) => theme.palette.Neutral750};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 6.4rem 0 4rem;
  }
`;

export const LinksItem = styled.div`
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    order: 1;
  }
`;

export const CustomerService = styled.div`
  margin-left: auto;
  text-align: right;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-left: unset;
    text-align: center;
  }
`;

export const CustomerServiceLabel = styled.div`
  display: inline;
  color: ${({ theme }) => theme.palette.TypoNeutral500};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
    margin-bottom: 0.8rem;
  }
`;
