'use client';

import styled from 'styled-components';
import { resolveTypographyVariant } from '@debt-relief/common-ui';

export const SectionTitle = styled.h2(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.display.xl),
  margin: 0,

  [`@media (max-width: ${theme.breakpoints.tablet})`]: {
    ...resolveTypographyVariant(theme.typography.display.m),
  },
}));
