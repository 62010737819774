import {
  type TFormApiSetValuesPayload,
  type TFormApiOpenParams as TFormApiOpenParamsBase,
  type TFormApi,
  type TFormApiMethodArgs,
  callFormApiMethod,
} from '@debt-relief/form-api';
import { logger } from '@shared/logger';

interface TFormApiOpenParams extends TFormApiOpenParamsBase {
  values?: TFormApiSetValuesPayload;
}

const callFormApiSafe = <M extends keyof TFormApi>(
  method: M,
  ...args: TFormApiMethodArgs<M>
): void => {
  callFormApiMethod(method, ...args).catch((error) => {
    logger.error(error);
  });
};

export const formApi = {
  openForm: ({ values, baseUrl, withNextStep }: TFormApiOpenParams) => {
    if (values) {
      callFormApiSafe('setValues', values);
    }

    callFormApiSafe('openForm', {
      baseUrl,
      withNextStep,
    });
  },
  setValues: (payload: TFormApiSetValuesPayload) => {
    callFormApiSafe('setValues', payload);
  },
};
