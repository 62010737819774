import React from 'react';
import type { Article } from '../../types';
import { ArticleImage, ReadMoreLink, Subtitle, Title } from './styles';

interface ArticleCardProps {
  article: Article;
}

export const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => (
  <article>
    <ArticleImage
      alt={article.title}
      height={article.image.height}
      src={article.image.src}
      width={article.image.width}
    />

    <Title>{article.title}</Title>

    <Subtitle>{article.subtitle}</Subtitle>

    <ReadMoreLink
      color="primary"
      href={article.link}
      target="_blank"
      underline="unhover"
    >
      Read more
    </ReadMoreLink>
  </article>
);
