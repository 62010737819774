'use client';

import styled from 'styled-components';
import { SunShafts as SunShaftsBase } from '@debt-relief/balanced-life-ui';
import { resolveTypographyVariant, Section } from '@debt-relief/common-ui';
import { DebtForm as DebtFormBase } from '@entities/debt-form/components/form';
import balancedGirlImage from './images/balanced-girl.png';

export const Root = styled.div`
  position: relative;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  background: radial-gradient(
    69.48% 370.06% at 69.48% 43.58%,
    #0d462a 0%,
    #05170b 54%
  );
  pointer-events: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    bottom: auto;
    height: 100%;
    background: radial-gradient(
      237.05% 100% at 50% 0%,
      #05170b 0%,
      #0d462a 59.51%,
      #05341c 100%
    );
  }
`;

export const GirlImage = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-36.9rem, 94px);
  width: ${balancedGirlImage.width}px;
  height: ${balancedGirlImage.height}px;
  background: no-repeat left bottom url('${balancedGirlImage.src}');
  background-size: 100%;
  z-index: 0;

  &:after {
    content: '';
    display: block;
    padding-bottom: ${(balancedGirlImage.height / balancedGirlImage.width) *
    100}%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    position: relative;
    bottom: 10rem;
    height: 100%;
    transform: translate(-1.5rem, 0) translate(-50%, 0) scale(1.45);
    width: 100%;
    max-width: 43.1rem;
  }
`;

export const SunShafts = styled(SunShaftsBase)`
  z-index: 1;
`;

export const HeroSection = styled(Section)`
  padding-top: 10.4rem;
  padding-bottom: 10.4rem;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 2.4rem;
    padding-bottom: 0;
  }
`;

export const HeroContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 45.1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    align-items: center;
    margin: 0 auto;
  }
`;

export const HeroTitle = styled.h1(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.display.xxxl),
  color: theme.palette.TypoNeutralContrast,
  maxWidth: '44.8rem',
  textAlign: 'left',
  margin: '0 0 4rem',

  [`@media (max-width: ${theme.breakpoints.tablet})`]: {
    ...resolveTypographyVariant(theme.typography.display.m),
    maxWidth: '34.2rem',
    textAlign: 'center',
  },
}));

export const HeroTitleHighlight = styled.span`
  color: ${({ theme }) => theme.palette.TypoPrimaryL200};
`;

export const DebtForm = styled(DebtFormBase)`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 30rem;
  }
`;
