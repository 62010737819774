import { useCallback, useState } from 'react';
import { DEBT_OPTIONS } from '@entities/debt-form/constants';

export const useDebtForm = (): {
  debtAmount: string;
  updateDebtAmount: (newDebtAmount: string) => void;
} => {
  const [debtAmount, setDebtAmount] = useState(DEBT_OPTIONS[1].value);

  const updateDebtAmount = useCallback((newDebtAmount: string): void => {
    setDebtAmount(newDebtAmount);
  }, []);

  return { debtAmount, updateDebtAmount };
};
