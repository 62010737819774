'use client';

import styled from 'styled-components';
import { resolveTypographyVariant, Button } from '@debt-relief/common-ui';
import { FormField } from '../form-field';

export const Root = styled.div`
  padding: 1.6rem;
  border-radius: 1.6rem;
  background: ${({ theme }) => theme.palette.TypoNeutralContrast};
  box-shadow: 0px 12px 120px 0px rgba(5, 55, 30, 0.32);
`;

export const FormTitle = styled.div(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.headline.m),
  marginBottom: '1.2rem',
  color: theme.palette.TypoNeutral100,
}));

export const FieldButton = styled(Button)`
  flex: 1 0 auto;
  min-width: 0;
  white-space: nowrap;
`;

export const DesktopFormField = styled(FormField)`
  display: block;
`;

export const MobileFormField = styled(FormField)`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }
`;
