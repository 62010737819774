import { type TDebtOption } from './types';

export const DEBT_AMOUNT_FLOOR = 5000;
export const MIN_ACCEPTABLE_DEBT_AMOUNT = 10000;
export const DEBT_OPTIONS: TDebtOption[] = [
  {
    value: String(DEBT_AMOUNT_FLOOR),
    label: 'Up to $10,000',
  },
  {
    value: String(MIN_ACCEPTABLE_DEBT_AMOUNT),
    label: '$10,000 - $14,999',
  },
  {
    value: '15000',
    label: '$15,000 - $19,999',
  },
  {
    value: '20000',
    label: '$20,000 - $49,999',
  },
  {
    value: '50000',
    label: '$50,000 - $74,999',
  },
  {
    value: '75000',
    label: '$75,000 - $100,000',
  },
];
