import { forwardRef } from 'react';
import { Icon } from '../icon';

export const ExpandIcon = forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => (
  <Icon
    {...props}
    fill="none"
    height="24"
    ref={ref}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      height="22"
      rx="11"
      stroke="currentColor"
      strokeWidth="2"
      width="22"
      x="1"
      y="1"
    />

    <path
      d="M12.0009 7V17M7 12.0003H17"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </Icon>
));

ExpandIcon.displayName = 'ArrowDownIcon';
