import React from 'react';
import { Section } from '@debt-relief/common-ui';
import { ArticleCard } from '@entities/articles/components/article-card';
import { ARTICLES } from '@entities/articles/constants';
import { Items, Item, Root, Title, TitleHighlight } from './styles';

export const BalancedLifeArticles: React.FC = () => {
  return (
    <Root>
      <Section paddings="lg">
        <Title>
          Read <TitleHighlight>&</TitleHighlight> Study
        </Title>

        <Items>
          {ARTICLES.map((article) => (
            <Item key={article.id}>
              <ArticleCard article={article} />
            </Item>
          ))}
        </Items>
      </Section>
    </Root>
  );
};
