import { forwardRef } from 'react';
import { Icon } from '../icon';

export const ChevronDownIcon = forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => (
  <Icon
    {...props}
    fill="none"
    height="24"
    ref={ref}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9L12 15L18 9"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </Icon>
));

ChevronDownIcon.displayName = 'ChevronDownIcon';
