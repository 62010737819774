import { gtag } from '@debt-relief/utils';
import { DEBT_OPTIONS } from '@entities/debt-form/constants';
import {
  JGUrl,
  moneyForOfferWallUrl,
  moneyForOfferWallUrlLessThen10000,
} from '@shared/api/constants';
import { redirectToMoneyFor } from './redirect-to-moneyfor';

interface TRedirectByDebtAmountProps {
  debtAmount: string;
  usState?: string;
  isJgRedirect: boolean;
}
export const redirectByDebtAmount = ({
  debtAmount,
  usState,
  isJgRedirect,
}: TRedirectByDebtAmountProps): void => {
  const redirectParams = {
    debt_amount: debtAmount,
    ...(usState ? { state: usState } : {}),
  };

  if (debtAmount === DEBT_OPTIONS[0].value) {
    gtag({ event: 'lessThan10000' });

    redirectToMoneyFor(moneyForOfferWallUrlLessThen10000, redirectParams);
    return;
  }

  gtag({ event: 'form_start' });
  isJgRedirect
    ? window.location.assign(JGUrl)
    : redirectToMoneyFor(moneyForOfferWallUrl, redirectParams);
};
