'use client';

import styled from 'styled-components';
import {
  ChevronDownIcon as ChevronDownIconBase,
  resolveTypographyVariant,
} from '@debt-relief/common-ui';

export const Root = styled.label`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  border-radius: 1.2rem;
  background: ${({ theme }) => theme.palette.Neutral75};
  flex: 1 1 100%;
  position: relative;

  &:hover {
    background: ${({ theme }) => theme.palette.Neutral125};
  }

  &:active {
    background: ${({ theme }) => theme.palette.Neutral175};
  }
`;

export const NativeSelect = styled.select`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const SelectValue = styled.span(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.body.m.paragraph),
  fontWeight: 500,
  color: theme.palette.TypoNeutral100,
}));

export const ChevronDownIcon = styled(ChevronDownIconBase)`
  color: ${({ theme }) => theme.palette.Neutral500};
`;
