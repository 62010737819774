'use client';

import styled from 'styled-components';
import { Button, CallIcon as CallIconBase } from '@debt-relief/common-ui';
import { Logo as LogoBase } from '../logo';

export const Root = styled.header`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0;
`;

export const Logo = styled(LogoBase)`
  height: 3.6rem;
  width: auto;
`;

export const CallButton = styled(Button)`
  line-height: calc(2.4 / 1.6);
`;

export const CallIcon = styled(CallIconBase)`
  width: 2rem;
  height: 2rem;
`;
