'use client';

import React from 'react';
import { Select } from '@debt-relief/balanced-life-ui';
import { type TDebtFormValues } from '../../types';
import { DEBT_OPTIONS } from '../../constants';
import { DesktopFormField, FieldButton, FormTitle, Root } from './styles';

interface TDebtFormProps {
  className?: string;
  onSubmit: (values: TDebtFormValues) => void;
  debtAmount: string;
  updateDebtAmount: (newDebtAmount: string) => void;
}

export const DebtForm: React.FC<TDebtFormProps> = ({
  className,
  onSubmit,
  debtAmount,
  updateDebtAmount,
}) => {
  const handleDebtAmountChange = (value: string): void => {
    updateDebtAmount(value);
  };

  const handleSubmitClick = (): void => {
    onSubmit({ debtAmount });
  };

  return (
    <Root className={className}>
      <FormTitle>
        Discover If You&apos;re Eligible for&nbsp;Debt Relief
      </FormTitle>

      <DesktopFormField label="How much debt do you have?">
        <Select
          id="debt"
          name="debt"
          onChange={handleDebtAmountChange}
          options={DEBT_OPTIONS}
          value={debtAmount}
        />

        <FieldButton color="accent" onClick={handleSubmitClick}>
          Get Started
        </FieldButton>
      </DesktopFormField>
    </Root>
  );
};
