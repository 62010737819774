/* eslint-disable no-console -- allow console for logging */
import * as Sentry from '@sentry/nextjs';
import { isProductionLocation, isStagingLocation } from '@shared/utils/env';

export const logger = {
  error(error: unknown): void {
    if (isStagingLocation() || isProductionLocation()) {
      Sentry.captureException(error);
    } else {
      console.error(error);
    }
  },

  info(...args: Parameters<typeof console.info>): void {
    console.info(...args);
  },

  log(...args: Parameters<typeof console.log>): void {
    console.log(...args);
  },
};
